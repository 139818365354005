<template>
    <BT-Blades
        v-if="currentLocation != null"
        bladeClass="pa-2"
        :maximizedCount="20"
        :anchorBlades="[{ bladeName: 'journeys-menu' }, { bladeName: 'supply-pointers' }, { bladeName: 'journey-templates' }, { bladeName: 'routes' }, { bladeName: 'journeys-map' }]">
        <template v-slot="bladesData">
            <BT-Blade
                bladeName="journeys-menu"
                :bladesData="bladesData"
                hideBladeHeader
                :loadingMsg="loadingMsg"
                :defaultBladeWidth="350">
                <v-tabs v-if="showTabHeader" v-model="currentTab" backgroundColor="primary" dark>
                    <v-tab v-if="showSupplyTab">Supply</v-tab>
                    <v-tab v-if="showRoutesTab">Routes</v-tab>
                    <v-tab v-if="showJourneysTab">Journeys</v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item v-if="showSupplyTab">
                        <BT-Blade-List
                            @select="selectSupplyPointer"
                            :bladesData="bladesData"
                            bladeName="supply-pointers"
                            :canAdd="false"
                            :canClose="false"
                            dense
                            :getParams="() => { return { includeDetails: true } }"
                            isChildBlade
                            navigation="supply-pointers"
                            :onPullSuccessAsync="pullSupplyPointers"
                            :searchProps="['weekdays']"
                            showSubtoolbar
                            title="Dispatch"
                            :actualUsedHeight="160">
                            <template v-slot:actions="{ allItems }">
                                <BT-Btn icon="mdi-eye-off-outline" @click="hideAllSupplyPointers" title="Hide All" />
                                <BT-Btn icon="mdi-plus" @click="addSupplyPointer(allItems)" title="Create New" />
                            </template>
                            <template v-slot="{ item, deleteItem, allItems }">
                                <v-list-item-avatar class="mx-0 px-0">
                                    <v-slide-x-transition>
                                        <v-btn v-if="item.id == null || $BlitzIt.tracker.isChanged(item)" icon @click.stop="saveSupplyPointer(item)">
                                            <v-icon>mdi-content-save</v-icon>
                                        </v-btn>
                                        <v-btn v-else-if="item.showArea && containsWeekday(item.weekdays, currentWeekday)" 
                                            class="success--text"
                                            icon 
                                            @click.stop="toggleSelected(item)" 
                                            title="Hide Area">
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn v-else-if="!item.showArea && containsWeekday(item.weekdays, currentWeekday)" 
                                            icon 
                                            @click.stop="toggleSelected(item)" 
                                            title="Show Area">
                                            <v-icon>mdi-eye-off</v-icon>
                                        </v-btn>
                                    </v-slide-x-transition>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-text-field
                                            v-if="currentSupplyPointer === item && item.destinationLocationID == null"
                                            @click.stop
                                            hide-details
                                            single-line
                                            v-model="item.pointerName" 
                                            label="Pointer Name" />
                                        <span v-else>{{ item.pointerName || '(Pointer Name)' }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ item.destinationLocationID != null ? 'FROM: ' : '' }}{{ item.location.locationName }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="item.destinationLocationID != null">
                                        <BT-Entity
                                            navigation="public-locations"
                                            :itemValue="item.destinationLocationID"
                                            single
                                            textFilter="toFamiliarLocationLine"
                                            useLocalCache>
                                            <template v-slot:prepend>TO: </template>
                                        </BT-Entity>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <BT-Chips-Menu
                                            v-model="item.weekdays"
                                            :canOpen="currentSupplyPointer != null && currentSupplyPointer.id == item.id"
                                            column
                                            isEditing
                                            :items="weekdayOptions()"
                                            multiple
                                            returnCSV
                                            xSmall />
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row class="ma-0 pa-0">
                                        <v-btn v-if="currentSupplyPointer === item && item.boundary != null" icon @click.stop="isMatching = true" title="Match Area">
                                            <v-icon>mdi-vector-selection</v-icon>
                                        </v-btn>
                                        <v-btn v-if="item.id == null" icon title="Delete" @click.stop="removeSupplyPointer(item, allItems)">
                                            <v-icon class="error--text">mdi-delete</v-icon>
                                        </v-btn>
                                        <v-btn v-else icon @click.stop="deleteItem(item)" title="Delete">
                                            <v-icon class="error--text">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-list-item-action>
                            </template>
                        </BT-Blade-List>
                    </v-tab-item>
                    <v-tab-item v-if="showRoutesTab">
                        <BT-Blade-List
                            @select="selectRoute"
                            :bladesData="bladesData"
                            bladeName="routes"
                            :bladesToClear="['journey-template-pointers']"
                            :canAdd="false"
                            :canClose="false"
                            dense
                            :getParams="() => { return { includeDetails: true } }"
                            isChildBlade
                            navigation="routes"
                            :onPullSuccessAsync="pullRoutes"
                            :searchProps="['routeName']"
                            selectBladeName="route"
                            showSubtoolbar
                            title="Routes"
                            :actualUsedHeight="160">
                            <template v-slot:actions="{ allItems }">
                                <BT-Btn icon="mdi-circle-off-outline" @click="hideAllRoutes" title="Hide All" />
                                <BT-Btn icon="mdi-plus" @click="addRoute(allItems)" title="Create New" />
                            </template>
                            <template v-slot="{ item, deleteItem, allItems }">
                                <v-list-item-avatar class="mx-0 px-0">
                                    <v-slide-x-transition hide-on-leave>
                                        <v-btn v-if="item.id == null || $BlitzIt.tracker.isChanged(item)" icon @click.stop="saveRoute(item)">
                                            <v-icon>mdi-content-save</v-icon>
                                        </v-btn>
                                        <v-btn v-else-if="item.showArea && containsWeekday(item.weekdays, currentWeekday)"
                                            class="success--text"
                                            icon 
                                            @click.stop="toggleSelected(item)" 
                                            title="Hide Area">
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn v-else-if="!item.showArea && containsWeekday(item.weekdays, currentWeekday)"
                                            icon 
                                            @click.stop="toggleSelected(item)" 
                                            title="Show Area">
                                            <v-icon>mdi-eye-off</v-icon>
                                        </v-btn>
                                        <!-- <v-btn v-else small icon @click.stop="toggleSelected(item)" title="Show Area">
                                            <v-icon small :color="item.showArea && containsWeekday(item.weekdays, currentWeekday) ? item.color : 'grey'">mdi-circle</v-icon>
                                        </v-btn> -->
                                    </v-slide-x-transition>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-text-field
                                            v-if="currentRoute != null && currentRoute.id == item.id"
                                            @click.stop
                                            hide-details
                                            v-model="item.routeName"
                                            label="Route Name" />
                                        <span v-else>{{ item.routeName || '(No Route Name)' }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="item.canBundle">
                                        <v-text-field
                                            v-if="currentRoute != null && currentRoute.id == item.id"
                                            @click.stop
                                            hide-details
                                            v-model="item.bundleName"
                                            label="Bundle Name" />
                                        <span v-else>{{ item.bundleName || '(No Bundle Name)' }}</span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-row class="ma-0 pa-0">
                                            <v-btn 
                                                v-if="currentRoute != null && currentRoute.id == item.id" 
                                                text
                                                title="Reroute" 
                                                @click.stop="reroute(item)">
                                                <v-icon left>mdi-map-marker-path</v-icon>Reroute
                                            </v-btn>
                                            <v-icon v-else class="my-1 mx-2">mdi-map-marker-path</v-icon>
                                            <v-btn 
                                                v-if="currentRoute != null && currentRoute.id == item.id" 
                                                :class="item.canBundle ? 'success--text' : ''"
                                                text 
                                                @click.stop="item.canBundle = !item.canBundle" 
                                                title="Can Bundle">
                                                <v-icon left>mdi-format-list-group</v-icon>Can Bundle
                                            </v-btn>
                                            <v-icon v-else class="my-1 mx-2">mdi-format-list-group</v-icon>
                                            <v-btn 
                                                v-if="currentRoute != null && currentRoute.id == item.id && item.canBundle" 
                                                :class="item.isRestrictedBundling ? 'success--text' : ''"
                                                text 
                                                @click.stop="item.isRestrictedBundling = !item.isRestrictedBundling" 
                                                title="Only Bundle Complete Matches">
                                                <v-icon left>mdi-group</v-icon>Only Bundle Matches
                                            </v-btn>
                                            <v-icon v-else-if="item.canBundle" class="my-1 mx-2">mdi-group</v-icon>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn v-if="item.id == null" icon title="Delete" @click.stop="removeRoutePointer(item, allItems)">
                                        <v-icon class="error--text">mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn v-else icon @click.stop="deleteItem(item)" title="Delete">
                                        <v-icon class="error--text">mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </template>
                        </BT-Blade-List>
                    </v-tab-item>
                    <v-tab-item v-if="showJourneysTab">
                        <BT-Blade-List
                            addBladeName="journey-template"
                            :bladesData="bladesData"
                            bladeName="journey-templates"
                            :bladesToClear="['route']"
                            :canClose="false"
                            canSearchLocal
                            dense
                            isChildBlade
                            :itemProperties="['ID','JourneyName','StartLocation','StartLocationID','EndLocation','EndLocationID']"
                            navigation="journey-templates"
                            :onPullSuccessAsync="pullJourneys"
                            :searchProps="['journeyName']"
                            selectBladeName="journey-template-pointers"
                            showSubtoolbar
                            title="Templates"
                            :actualUsedHeight="160">
                            <template v-slot:actions="{ add }">
                                <BT-Btn icon="mdi-circle-off-outline" small @click="hideAllJourneyPointers" title="Hide All" />
                                <BT-Btn icon="mdi-plus" @click="add" small title="Create New" />
                            </template>
                            <template v-slot="{ item }">
                                <v-list-item-avatar class="mx-0 px-0">
                                    <v-btn v-if="item.showArea" icon @click.stop="toggleSelected(item)" title="Hide Area" class="success--text">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn v-else icon @click.stop="toggleSelected(item)" title="Show Area">
                                        <v-icon>mdi-eye-off</v-icon>
                                    </v-btn>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.startLocationID == item.endLocationID">{{ item.startLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>{{ item.startLocation.locationName }} --> {{ item.endLocation.locationName }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </BT-Blade-List>
                    </v-tab-item>
                </v-tabs-items>
            </BT-Blade>

            <Journey-Template-Blade :bladesData="bladesData" />
            
            <Route-Blade
                :bladesData="bladesData"
                :center="currentLocation"
                :currentWeekday="currentWeekday"
                :routePointers="routePointers"
                @match="isMatching = true"
                @reroute="reroute"
                @selectMovement="selectRouteLocation"
                @selectPointer="selectRoutePointer" />
            
            <Journey-Template-Pointers-Blade
                :bladesData="bladesData"
                :currentWeekday="currentWeekday"
                :pointers="journeyPointers"
                @match="isMatching = true"
                @select="selectJourneyPointer" />

            <BT-Blade
                :bladesData="bladesData"
                bladeName="journeys-map"
                :canClose="false"
                :canPin="false"
                :canMinimize="false"
                :canRefresh="false"
                flexColumn
                :loadingMsg="loadingMsg"
                :title="isRouting ? 'Rerouting' : 'Coverage'">
                <template v-slot:toolbar-left>
                    <BT-Chips
                        v-model="currentWeekday"
                        class="ml-4"
                        isEditing
                        :items="weekdayOptions()"
                        returnCSV />
                </template>
                <template v-slot:toolbar-right>
                    <BT-Menu
                        :icon="null"
                        openOnHover
                        small
                        text="Hide/Show"
                        title="Hide/Show">
                        <template v-slot>
                            <v-list-item :class="showSupplyPointers ? 'success--text' : ''" small @click="showSupplyPointers = !showSupplyPointers">
                                <v-list-item-icon><v-icon v-if="showSupplyPointers">mdi-check</v-icon></v-list-item-icon>
                                Show Supply
                            </v-list-item>
                            <v-list-item :class="showRoutePointers ? 'success--text' : ''" small @click="showRoutePointers = !showRoutePointers">
                                <v-list-item-icon><v-icon v-if="showRoutePointers">mdi-check</v-icon></v-list-item-icon>
                                Show Routes
                            </v-list-item>
                            <v-list-item :class="showJourneyPointers ? 'success--text' : ''" small @click="showJourneyPointers = !showJourneyPointers">
                                <v-list-item-icon><v-icon v-if="showJourneyPointers">mdi-check</v-icon></v-list-item-icon>
                                Show Journeys
                            </v-list-item>
                            <v-list-item :class="showMyLocations ? 'success--text' : ''" small @click="showMyLocations = !showMyLocations">
                                <v-list-item-icon><v-icon v-if="showMyLocations">mdi-check</v-icon></v-list-item-icon>
                                Show My Locations
                            </v-list-item>
                            <v-list-item :class="showCustomers ? 'success--text' : ''" small @click="showCustomers = !showCustomers">
                                <v-list-item-icon><v-icon v-if="showCustomers">mdi-check</v-icon></v-list-item-icon>
                                Show Customers
                            </v-list-item>
                        </template>
                    </BT-Menu>
                </template>
                <template>
                    <BT-Fast-Start-Banner />
                    <GmapMap
                        :center="currentLocation"
                        :zoom="7"
                        ref="map"
                        style="width: 100%; height: calc(100vh - 112px)"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false }">

                        <!-- supply pointer areas -->
                        <gmap-polygon
                            v-for="(pointer, index) in filteredSupplyPointers"
                            @click="selectSupplyPointer(pointer)"
                            @paths_changed="($event) => { updatePointerPaths($event, pointer) }"
                            clickable
                            :editable="currentSupplyPointer != null && currentSupplyPointer._btID == pointer._btID"
                            :fillOpacity="0.5"
                            :key="index + 'a'"
                            :paths="pointer.boundary"
                            :strokeColor="pointer.color"
                            :strokeWeight="2" />

                        <!-- supply pointer line connectors -->
                        <!-- <gmap-polyline
                            v-for="(pointer, index) in filteredSupplyPointers"
                            :editable="false"
                            :key="index + 'b'"
                            :path="[pointer.location, pointer.boundary[0]]"
                            :strokeColor="pointer.color" /> -->
                        <gmap-polyline
                            v-if="currentSupplyPointer != null && showSupplyPointers && containsWeekday(currentSupplyPointer.weekdays, currentWeekday) && (currentSupplyPointer.boundary != null || currentSupplyPointer.destinationLocation != null)"
                            clickable
                            @click="selectSupplyPointer(currentSupplyPointer)"
                            :editable="false"
                            key="b"
                            :path="[currentSupplyPointer.location, currentSupplyPointer.boundary != null ? currentSupplyPointer.boundary[0] : currentSupplyPointer.destinationLocation]"
                            :strokeColor="currentSupplyPointer.color" />

                        <!-- supply pointer supply locations -->
                        <!-- <gmap-marker
                            v-for="(pointer, index) in filteredSupplyPointers"
                            clickable
                            @click="selectSimpleLocation(pointer.location)"
                            :icon="{ anchor: { x: 13, y: 23 }, path: pinSVGFilled, fillOpacity: 1, fillColor: pointer.color }"
                            :key="index + 'c'"
                            :position="pointer.location" /> -->
                        <gmap-marker
                            v-if="currentSupplyPointer != null && showSupplyPointers && containsWeekday(currentSupplyPointer.weekdays, currentWeekday)"
                            clickable
                            @click="selectSimpleLocation(currentSupplyPointer.location)"
                            :icon="{ anchor: { x: 13, y: 23 }, path: pinSVG(), fillOpacity: 1, fillColor: currentSupplyPointer.color }"
                            key="1c"
                            :position="currentSupplyPointer.location" />

                        <!-- journey pointer areas -->
                        <gmap-polygon
                            v-for="(pointer, index) in filteredJourneyPointers"
                            clickable
                            @click="selectJourneyPointer(pointer)"
                            :key="index + 'd'"
                            :paths="pointer.boundary"
                            :strokeColor="pointer.color"
                            :strokeWeight="2"
                            :fillOpacity="0.5"
                            @paths_changed="($event) => { updatePointerPaths($event, pointer) }"
                            :editable="currentJourneyPointer != null && currentJourneyPointer._btID == pointer._btID" />

                        <!-- journey location markers -->
                        <gmap-marker
                            v-for="(p, index) in journeyLocations"
                            clickable
                            @click="selectSimpleLocation(p.location)"
                            :icon="{ anchor: { x: 13, y: 23 }, path: pinSVG(), fillOpacity: 1, fillColor: p.color }"
                            :position="p.location"
                            :key="index + 'e'" />

                        <!-- customer markers -->
                        <gmap-marker
                            v-for="(position, index) in customerLocations"
                            clickable
                            @click="selectSimpleLocation(position)"
                            :position="position"
                            :key="index + 'f'" />

                        <!-- route pointer markers -->
                        <gmap-marker
                            v-for="(marker, index) in filteredRouteMarkers"
                            clickable
                            @click="selectRoutePointer(marker, currentRoute)"
                            :position="marker.location"
                            :key="index + 'fa'" />

                        <!-- route pointer areas -->
                        <gmap-polygon
                            v-for="(routePointer, index) in filteredRoutePointers"
                            clickable
                            @click="selectRoutePointer(routePointer)"
                            :key="index + 'g'"
                            :paths="routePointer.boundary"
                            :strokeColor="routePointer.color"
                            :strokeWeight="2"
                            :fillOpacity="0.5"
                            @paths_changed="($event) => { updatePointerPaths($event, routePointer) }"
                            :editable="currentRoutePointer === routePointer" />

                        <!-- my location markers -->
                        <gmap-marker
                            v-for="(position, index) in filteredMyLocations"
                            clickable
                            @click="selectRouteLocation(position, currentRoute)"
                            :position="position.courierLocation"
                            :key="index + 'ha'" />

                        <!-- route location markers -->
                        <gmap-marker
                            v-for="(position, index) in routingLocations"
                            clickable
                            @click="selectRouteLocation(position, currentRoute)"
                            :position="position.courierLocation"
                            :key="index + 'i'" />
                        <!-- :label="position.sortOrder != null ? position.sortOrder.toString() : ''" -->

                        <!-- current route path -->
                        <gmap-polyline
                            v-if="currentRoute != null && isLengthyArray(currentRoute.movements)" 
                            :editable="false"
                            key="1j"
                            :path="[...currentRoute.movements.map(x => x.courierLocation)]"
                            :strokeColor="currentRoute.color" />

                        <!-- selection window -->
                        <gmap-info-window
                            :opened="selectedInfo != null"
                            :options="windowOptions"
                            :position="infoPosition"
                            @closeclick="selectedInfo = null"
                            key="1k">
                            <template v-slot:default>
                                <v-card v-if="selectedInfo != null && selectedInfo.lat != null && selectedInfo.lng != null" class="transparent primary--text pa-0 ma-0">
                                    <v-card-title v-if="selectedInfo.companyAccount != null" class="primary--text">
                                        {{ selectedInfo.companyAccount.companyName }}
                                    </v-card-title>
                                    <v-card-title v-else class="primary--text">{{ selectedInfo.locationName }}</v-card-title>
                                    <v-card-subtitle class="primary--text">{{ selectedInfo | toLocationLine }}</v-card-subtitle>
                                </v-card>
                                <v-card v-else-if="selectedInfo === 'From'" class="transparent primary--text pa-0 ma-0">
                                    <v-card-title class="ma-0 pa-0">From</v-card-title>
                                    <v-card-text class="ma-0 pa-0">
                                        <v-checkbox
                                            v-model="currentRoute.startCanPickup"
                                            dense
                                            hide-details
                                            label="Pickup From Clients"
                                            light />

                                        <v-btn
                                            @click="reroute"
                                            block
                                            class="primary mt-2"
                                            small>
                                            Select Route
                                        </v-btn>

                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn v-if="isRouting" block small class="primary" @click="selectLastLeg">
                                            Last Leg
                                        </v-btn>
                                        <!-- <v-btn v-else class="primary" block small @click="nextLocation(currentRoute)">
                                            Next<v-icon right>mdi-arrow-right</v-icon>
                                        </v-btn> -->
                                    </v-card-actions>
                                </v-card>
                                <v-card v-else-if="selectedInfo === 'To'" class="transparent primary--text pa-0 ma-0">
                                    <v-card-title class="ma-0 pa-0">Last Leg</v-card-title>
                                    <v-card-subtitle v-if="currentRoutePointer != null && currentRoutePointer.location != null">{{ currentRoutePointer.location | toFamiliarLocationLine }}</v-card-subtitle>
                                    <v-card-text class="ma-0 pa-0">
                                        <v-checkbox
                                            v-model="currentRoute.isAutoDeliver"
                                            dense
                                            hide-details
                                            label="Auto Complete Delivery"
                                            light />

                                        <BT-Toggle
                                            v-model="currentRoute.isFixed"
                                            class="my-4"
                                            :dark="false"
                                            falseText="Relative"
                                            light
                                            trueText="Same Day As Departure" />

                                        <v-text-field
                                            v-if="currentRoute.endCanDropoff"
                                            v-model.number="currentRoute.lastLegMinutesToArrival"
                                            hide-details
                                            label="Minutes to arrival"
                                            light
                                            type="number" />

                                    </v-card-text>
                                </v-card>
                                <v-card v-else-if="selectedInfo != null && selectedInfo.courierLocation != null" class="transparent primary--text pa-0 ma-0" width="300">
                                    <v-card-title v-if="selectedInfo.courierLocation.companyAccount != null" class="primary--text text-center">
                                        #{{ selectedInfo.sortOrder }}<br />
                                        {{ selectedInfo.courierLocation.companyAccount.companyName }}
                                    </v-card-title>
                                    <v-card-title v-else class="primary--text">{{ selectedInfo.courierLocation.locationName }}</v-card-title>
                                    <v-card-subtitle class="primary--text">{{ selectedInfo.courierLocation | toLocationLine }}</v-card-subtitle>
                                    <v-card-text class="ma-0 pa-0">

                                        <v-checkbox
                                            v-if="selectedInfo.sortOrder == 1"
                                            v-model="currentRoute.startCanDropoff"
                                            dense
                                            hide-details
                                            label="Clients can dropoff"
                                            light />

                                        <v-checkbox
                                            v-if="selectedInfo.sortOrder == currentRoute.movements.length"
                                            v-model="currentRoute.endCanPickup"
                                            dense
                                            hide-details
                                            label="Clients can pickup"
                                            light />
                                            
                                        <v-checkbox
                                            v-if="selectedInfo.sortOrder == currentRoute.movements.length"
                                            v-model="currentRoute.endCanDropoff"
                                            dense
                                            hide-details
                                            label="Can dropoff at clients'"
                                            light />
                                        
                                        <v-checkbox
                                            v-model="selectedInfo.isAutoDeliver"
                                            dense
                                            hide-details
                                            label="Auto Complete Delivery"
                                            light />

                                        <BT-Toggle
                                            v-model="selectedInfo.isFixed"
                                            class="my-4"
                                            :dark="false"
                                            falseText="Relative"
                                            light
                                            trueText="Fixed" />

                                        <BT-Chips-Menu
                                            v-if="selectedInfo.isFixed"
                                            v-model="selectedInfo.weekdays"
                                            column
                                            isEditing
                                            :items="weekdayOptions()"
                                            label="Fixed Weekdays"
                                            multiple
                                            returnCSV
                                            xSmall />

                                        <BT-Time-Picker
                                            v-if="selectedInfo.isFixed"
                                            v-model="selectedInfo.minuteOfDay"
                                            :dark="false"
                                            label="Time Of Day"
                                            light
                                            returnMinuteOfDay
                                            :isHourly="false" />

                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model.number="selectedInfo.minutesToArrival"
                                                    hide-details
                                                    label="Minutes to arrival"
                                                    light
                                                    type="number" />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model.number="selectedInfo.minutesToDeparture"
                                                    hide-details
                                                    label="Minutes to departure"
                                                    light
                                                    type="number" />
                                            </v-col>
                                        </v-row>
                                    
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn v-if="isRouting" block small class="primary" @click="selectLastLeg">
                                            Last Leg
                                        </v-btn>
                                        <!-- <v-btn v-else class="primary" block small @click="nextLocation(currentRoute)">
                                            Next<v-icon right>mdi-arrow-right</v-icon>
                                        </v-btn> -->
                                    </v-card-actions>
                                </v-card>
                                <v-card v-else-if="selectedInfo != null" class="transparent primary--text pa-0 ma-0">
                                    <!-- <v-card-title class="primary--text">Supply Pointer</v-card-title> -->
                                    <v-card-title class="primary--text">
                                        <v-edit-dialog>
                                            <span>{{ selectedInfo.pointerName || 'Pointer' }}</span>
                                            <template v-slot:input>
                                                <v-text-field
                                                    v-model="selectedInfo.pointerName"
                                                    single-line />
                                            </template>
                                        </v-edit-dialog>
                                    </v-card-title>
                                    <v-card-subtitle class="primary--text">{{ selectedInfo.location | toLocationLine }}</v-card-subtitle>
                                    <v-card-text class="ma-0 pa-0">
                                        <BT-Chips-Menu
                                            v-model="selectedInfo.weekdays"
                                            column
                                            isEditing
                                            :items="weekdayOptions()"
                                            multiple
                                            :openOnHover="false"
                                            returnCSV
                                            xSmall />
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn v-if="selectedInfo.isDestination != 'undefined' && $BlitzIt.tracker.isChanged(selectedInfo)" class="primary" block @click="saveSupplyPointer(selectedInfo)">Save</v-btn>
                                    </v-card-actions>
                                </v-card>

                            </template>
                        </gmap-info-window>

                    </GmapMap>
                    
                </template>
            </BT-Blade>

            <vue-draggable-resizable v-if="showCustomers" :x="300" :y="100" :h="500" :w="300" :z="2">
                <BT-Blade-List
                    :canRefresh="false"
                    canSearchLocal
                    :canSelect="false"
                    dense
                    hideBackButton
                    navigation="customers"
                    :itemProperties="['ID','Buyer']"
                    :onFilter="filterCustomers"
                    :onPullSuccessAsync="pullCustomers"
                    :searchProps="['buyer.companyName']"
                    showList
                    title="Customers">
                    <template v-slot:toolbar-right>
                        <v-btn small icon @click="showCustomers = false">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:actions>
                        <v-btn small icon @click="deselectCustomers" title="Hide All Customers">
                            <v-icon small>mdi-eye-off</v-icon>
                        </v-btn>
                        <v-btn small icon @click="showSelectedCustomers = !showSelectedCustomers" title="Show only selected customers">
                            <v-icon small>{{ showSelectedCustomers ? 'mdi-filter-off' : 'mdi-filter' }}</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:items="{ items }">
                        <v-list class="overflow-y-auto" style="height: calc(500px - 96px)">
                            <template v-for="(item, index) in items">
                                <drag
                                    :key="index"
                                    :data="item"
                                    type="customer">
                                    <v-list-item dense @click="selectCustomer(item)">
                                        <v-list-item-icon>
                                            <v-btn small icon @click="showCustomer(item)" class="my-auto" :loading="item.isLoading">
                                                <v-icon small>{{ item.selected ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </drag>
                            </template>
                        </v-list>
                    </template>
                </BT-Blade-List>
            </vue-draggable-resizable>

            <vue-draggable-resizable v-if="showPointers" :x="300" :y="150" :h="500" :w="300" :z="2">
                <BT-Blade-List
                    :canRefresh="false"
                    canSearchLocal
                    :canSelect="false"
                    dense
                    :items="journeyPointers"
                    hideBackButton
                    :searchProps="['location.companyAccount.companyName']"
                    showList
                    title="Pointers"
                    :actualUsedHeight="500">
                    <template v-slot="da">
                        <v-list-item-content>
                            <v-list-item-title v-if="da.item.location == null">{{ da.item.pointerName }}</v-list-item-title>
                            <v-list-item-title v-else>{{ da.item.location.companyAccount.companyName }}</v-list-item-title>
                            <v-list-item-subtitle v-if="da.item.location != null">{{ da.item.location | toLocationLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <BT-Chips-Menu
                                    v-model="da.item.weekdays"
                                    column
                                    isEditing
                                    :items="weekdayOptions()"
                                    multiple
                                    :openOnHover="false"
                                    returnCSV
                                    xSmall />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row>
                                <!-- <v-btn v-if="$BlitzIt.tracker.isChanged(da.item)" small icon @click.stop="savePointer(da.item, save)">
                                    <v-icon small>mdi-content-save</v-icon>
                                </v-btn> -->
                                <v-btn small icon @click="deletePointer(da.item, deleteItem)">
                                    <v-icon small class="error--text">mdi-delete</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </template>
                </BT-Blade-List>
            </vue-draggable-resizable>
        </template>
    </BT-Blades>
</template>

<script>
import { Drag } from 'vue-easy-dnd';
import { getColors, getOtherColors, weekdaysValue } from '~helpers';
import { firstBy } from 'thenby';
import { getGoogleMapsAPI } from 'gmap-vue';

export default {
    name: 'Journey-Template-Coverage-Blade',
    components: {
        BTChips: () => import('~components/BT-Chips.vue'),
        BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTTimePicker: () => import('~components/BT-Time-Picker.vue'),
        BTToggle: () => import('~components/BT-Toggle.vue'),
        JourneyTemplateBlade: () => import('~home/journey-templates/Journey-Template-Blade.vue'),
        JourneyTemplatePointersBlade: () => import('~home/journey-templates/Journey-Template-Pointers-Blade.vue'),
        RouteBlade: () => import('~home/route-pointers/Route-Blade.vue'),
        VueDraggableResizable: () => import('vue-draggable-resizable'),
        Drag
    },
    data: function() {
        return {
            colors: getColors(),
            colorsTwo: getOtherColors(),

            colorSupply: '#0b5394',
            colorRoute: '#741b47',
            colorJourney: '#b45f06',

            courierLocations: [],
            currentLocation: null,
            currentJourneyPointer: null,
            currentRoute: null,
            currentRoutePointer: null,
            currentSupplyPointer: null,
            currentTab: 0,
            currentWeekday: null, //'Always',
            customers: [],
            customerLocations: [],
            infoPosition: null,
            isMatching: false,
            isRouting: false,
            journeys: [],
            loadingMsg: null,
            myLocations: [],
            //pinSVGFilled: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
            //pinSVGFilled: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
            //pinSVGFilled: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
            
            journeyPointers: [],
            routes: [],
            routePointers: [],
            showCustomers: false,
            showPointers: false,
            showSelectedCustomers: false,

            showSupplyTab: true,
            showRoutesTab: true,
            showJourneysTab: true,

            showJourneyPointers: true,
            showMyLocations: true,
            showRoutePointers: true,
            showSupplyPointers: true,

            supplyPointers: [],
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            
            selectedInfo: null, //From, To, Object
            windowOptions: {
                
            }
        }
    },
    computed: {
        google: getGoogleMapsAPI,
        // customerLocations() {
        //     if (this.currentRoute != null || this.isRouting) {
        //         return [];
        //     }

        //     return this.showCustomers ? this.customers.filter(z => z.selected && z.locations != null).flatMap(m => m.locations) : []; //.filter(x => x.selected && this.isLengthyArray(x.locations)).flatMap(m => m.locations) : [];
        // },
        journeyLocations() {
            if (this.currentRoute != null || this.isRouting || !this.showJourneyPointers) {
                return [];
            }

            var selectedJourneys = this.journeys.filter(z => z.showArea);
            return this.journeyPointers.filter(pointer => this.containsWeekday(pointer.weekdays, this.currentWeekday) && pointer.location != null && selectedJourneys.some(z => z.id == pointer.journeyTemplateID));
        },
        routingLocations() {
            return this.isRouting ? this.courierLocations : this.filteredRoutes.flatMap(z => z.movements);
        },
        filteredMyLocations() {
            return this.isRouting || this.showMyLocations ? this.myLocations : [];
        },
        filteredJourneyPointers() {
            if (!this.showJourneyPointers) {
                return [];
            }

            var selectedJourneys = this.journeys.filter(z => z.showArea);
            return this.journeyPointers.filter(pointer => pointer.showArea && this.containsWeekday(pointer.weekdays, this.currentWeekday) && pointer.location == null && selectedJourneys.some(z => z.id == pointer.journeyTemplateID));
        },
        filteredRoutes() {
            return this.showRoutePointers ? this.routes.filter(route => route.showArea) : [];
        },
        filteredRoutePointers() {
            // if (this.currentRoute != null && this.isRouting) {
            //     return this.routePointers.filter(z => z.courierRouteID == this.currentRoute.id);
            // }
            return this.showRoutePointers ? this.routePointers.filter(p => this.containsWeekday(p.weekdays, this.currentWeekday) && p.locationID == null && this.filteredRoutes.some(r => r.id == p.courierRouteID)) : [];
        },
        filteredRouteMarkers() {
            if (this.isRouting) {
                return [];
            }

            return this.showRoutePointers ? this.routePointers.filter(p => this.containsWeekday(p.weekdays, this.currentWeekday) && p.boundary == null && p.locationID != null && this.filteredRoutes.some(r => r.id == p.courierRouteID)) : [];
        },
        filteredSupplyPointers() {
            return this.isRouting || !this.showSupplyPointers ? [] : this.supplyPointers.filter(z => this.containsWeekday(z.weekdays, this.currentWeekday) && z.showArea && z.boundary != null);
        },
        showTabHeader() {
            var arr = [this.showSupplyTab, this.showRoutesTab, this.showJourneysTab];
            return arr.filter(x => x).length > 1;
        }
    },
    async mounted() {
        var q = this.$route.query;
        if (q != null) {
            if (q.mode != null) {
                // this.showSupplyTab = false;
                // this.showRoutesTab = false;
                // this.showJourneysTab = false;

                if (q.mode == 'supply-pointers') {
                    this.showSupplyTab = true;
                    this.showRoutesTab = false;
                    this.showJourneysTab = false;
                }
                if (q.mode == 'route-pointers') {
                    this.showSupplyTab = false;
                    this.showRoutesTab = true;
                    this.showJourneysTab = false;
                }
                if (q.mode == 'journey-pointers') {
                    this.showSupplyTab = false;
                    this.showRoutesTab = false;
                    this.showJourneysTab = true;
                }
            }
        }

        var locations = await this.$BlitzIt.store.getAll('locations');
        this.locations = [];
        locations.forEach(location => {
            this.myLocations.push({
                id: null,
                courierLocationID: location.id,
                courierLocation: location,
                minutesToArrival: 0,
                minutesToDeparture: 0,
                isAutoDeliver: false,
                sortOrder: null
            });

            this.currentLocation = location;
        });

        this.refocusMap();
    },
    methods: {
        addMatch(boundary) {
            this.isMatching = false;

            if (boundary == null) {
                return;
            }

            var copiedBoundary = [...boundary];

            if (this.currentSupplyPointer != null) {
                if (this.currentSupplyPointer.boundary != null) {
                    this.currentSupplyPointer.boundary = copiedBoundary;
                }
            }
            else if (this.currentJourneyPointer != null) {
                if (this.currentJourneyPointer.boundary != null) {
                    this.currentJourneyPointer.boundary = copiedBoundary;
                }
            }
            else if (this.currentRoutePointer != null) {
                if (this.currentRoutePointer.boundary != null) {
                    this.currentRoutePointer.boundary = copiedBoundary;
                }
            }
        },
        async addRoute(routes) {
            var ind = this.routes.length;
            do {
                if (ind > 12) {
                    ind -= 12;
                }
            } while (ind > 12);

            var newRoute = {
                id: null,
                bundleName: null,
                endCanPickup: true,
                endCanDropoff: true,
                canBundle: false,
                isAutoDeliver: false,
                isRestrictedBundling: false,
                lastLegMinutesToArrival: 0,
                routeName: null,
                movements: [],
                startCanPickup: true,
                startCanDropoff: true
            };

            newRoute = this.$BlitzIt.tracker.track(newRoute, {
                ignoreProps: ['loadingCount', 'errorMsg', 'color', 'showArea'],
                assignIgnored: { showArea: true, color: this.colorRoute }
            });

            routes.unshift(newRoute);

            this.currentRoute = newRoute;

            this.refocusMap();
        },
        async addSupplyPointer(pointers) {
            var location = await this.$selectItem({
                navigation: 'locations',
                itemText: 'locationName',
                label: 'Supply Location'
            });

            if (location != null) {
                var ind = this.supplyPointers.length;
                do {
                    if (ind > 12) {
                        ind -= 12;
                    }
                } while (ind > 12);

                var newPointer = {
                    id: null,
                    rowVersion: null,
                    boundary: this.getAreaAround(location, 0.5),
                    pointerName: null,
                    weekdays: this.currentWeekday,
                    locationID: location.id,
                    location: location,
                    color: this.colorSupply
                };

                newPointer = this.$BlitzIt.tracker.track(newPointer, {
                    trackProps: ['id', 'rowVersion', 'boundary', 'pointerName', 'weekdays'],
                    assignIgnored: { showArea: true }
                })

                pointers.unshift(newPointer);

                this.currentSupplyPointer = newPointer;

                this.refocusMap();
            }
        },

        deselectCustomers() {
            this.customers.forEach(cust => {
                cust.selected = false;
            })

            this.refocusMap();
        },
        filterCustomers(customers) {
            return this.showSelectedCustomers ? customers.filter(z => z.selected) : customers;
        },
        filterSupplyPointers(pointers) {
            pointers.sort(firstBy(y => y.destinationLocationID != null).thenBy(z => !this.containsWeekday(z.weekdays, this.currentWeekday)));
            return pointers;
        },

        hideAllJourneyPointers() {
            this.journeys.forEach(j => {
                j.showArea = false;
            });

            this.refocusMap();
        },
        hideAllRoutes() {
            this.routes.forEach(r => {
                r.showArea = false;
            });

            this.refocusMap();
        },
        hideAllRoutePointers() {
            this.routes.forEach(r => {
                r.showArea = false;
            })

            this.refocusMap();
        },
        hideAllSupplyPointers() {
            this.supplyPointers.forEach(s => {
                s.showArea = false;
            })

            this.refocusMap();
        },
        nextLocation(route) {
            if (this.selectedInfo != null) {
                if (this.selectedInfo == 'From') {
                    if (route.movements.length > 0) {
                        this.selectRouteLocation(route.movements[0]);
                    }
                    else {
                        //this.openInfo(route);
                        this.selectLastLeg();
                    }
                }
                else {
                    var ind = route.movements.findIndex(x => x.courierLocationID == this.selectedInfo.courierLocationID);
                    if (ind > -1 && ind < route.movements.length - 1) {
                        this.selectRouteLocation(route.movements[ind + 1]);
                    }
                    else {
                        this.selectLastLeg();
                        //this.openInfo(route);
                    }
                }
            }
        },
        openInfo(routePointer) {
            if (routePointer.boundary != null) {
                this.infoPosition = routePointer.boundary[2];
            }
            else {
                this.infoPosition = routePointer.location;
            }

            if (routePointer != null && routePointer.isDestination != undefined) {
                this.selectedInfo = routePointer.isDestination ? 'To' : 'From';
            }
            else {
                this.selectedInfo = routePointer;
            }
            
            this.windowOptions = {
                pixelOffset: {
                    width: 0,
                    height: 0
                }
            }

            this.refocusMap();
        },
        pullCustomers(customers) {
            var cList = customers.map(x => {
                return Object.assign({}, { ...x, selected: false, locations: null, isLoading: false });
            })
            this.customers = cList;
            return this.customers;
        },
        async pullJourneys(journeys) {
            var res = journeys.map((x, ind) => {
                var i = ind;
                do {
                    if (i >= 15) {
                        i -= 15;
                    }
                } while (i >= 15);

                return Object.assign({}, { showArea: true, ...x, color: this.colorJourney });
            });

            var pointerRes = await this.$BlitzIt.store.getAll('journey-pointers', { includeDetails: true });
            
            this.journeyPointers = pointerRes.map(x => {
                //var tem = res.find(t => t.id == x.journeyTemplateID);
                return this.$BlitzIt.tracker.track(x, {
                    trackProps: ['boundary', 'id', 'rowVersion', 'locationID', 'weekdays', 'pointerName'],
                    assignIgnored: { showArea: true, color: this.colorJourney }
                });
            });

            this.journeys = res;

            return res;
        },
        async pullRoutes(routes) {
            var courierLocations = await this.$BlitzIt.store.getAll('couriers', null, false, null, '/getAll/CourierLocations');

            courierLocations.forEach(location => {
                this.courierLocations.push({
                    id: null,
                    courierLocationID: location.id,
                    courierLocation: location,
                    minutesToArrival: 0,
                    minutesToDeparture: 0,
                    isAutoDeliver: false,
                    sortOrder: null
                });
            });

            var pointerRes = await this.$BlitzIt.store.getAll('route-pointers', { includeDetails: true });

            this.routePointers = pointerRes.map(x => {
                return this.$BlitzIt.tracker.track(x, {
                    trackProps: ['id', 'rowVersion', 'pointerName', 'isDestination', 'boundary', 'weekdays'],
                    assignIgnored: { showArea: false, color: this.colorRoute }
                })
            })

            this.routes = routes.map(route => {
                route.movements = route.movements.orderBy('sortOrder');
                return this.$BlitzIt.tracker.track(route, {
                    ignoreProps: ['loadingCount', 'errorMsg'],
                    assignIgnored: { showArea: false, color: this.colorRoute }
                })
            });

            return this.routes;
        },
        async pullSupplyPointers(pointers) {
            this.supplyPointers = pointers.sort(firstBy(x => weekdaysValue(x.weekdays)).thenBy(x => x.boundary != null).thenBy(x => x.pointerName)).map(pointer => {
                return this.$BlitzIt.tracker.track(pointer, {
                    trackProps: ['id', 'rowVersion', 'boundary', 'weekdays', 'pointerName'],
                    assignIgnored: { showArea: true, color: this.colorSupply } //this.colors[e] }
                })
            });

            return this.supplyPointers;
        },
        refocusMap() {
            if (this.google == null || this.google.maps == null) {
                return;
            }
            
            var bounds = new this.google.maps.LatLngBounds();

            this.filteredSupplyPointers.forEach(p => {
                bounds.extend(p.boundary);
            })

            this.filteredJourneyPointers.forEach(j => {
                bounds.extend(j.boundary);
            })

            this.journeyLocations.forEach(u => {
                bounds.extend(u);
            })

            this.routingLocations.forEach(l => {
                bounds.extend(l.courierLocation);
            })

            this.filteredMyLocations.forEach(u => {
                bounds.extend(u.courierLocation);
            })

            this.filteredRouteMarkers.forEach(u => {
                bounds.extend(u.location);
            })

            this.filteredRoutePointers.forEach(p => {
                bounds.extend(p.boundary);
            })



            this.$refs.map.fitBounds(bounds);
        },
        removeRoutePointer(item, allItems) {
            if (item.id == null) {
                var ind = allItems.findIndex(z => z._btID === item._btID);
                if (ind >= 0) {
                    allItems.splice(ind, 1);
                }
                
                ind = this.routes.findIndex(z => z._btID === item._btID);
                if (ind >= 0) {
                    this.routes.splice(ind, 1);
                }
            }

            this.refocusMap();
        },
        removeSupplyPointer(item, allItems) {
            if (item.id == null) {
                var ind = allItems.findIndex(z => z._btID === item._btID);
                if (ind >= 0) {
                    allItems.splice(ind, 1);
                }
                
                ind = this.supplyPointers.findIndex(z => z._btID === item._btID);
                if (ind >= 0) {
                    this.supplyPointers.splice(ind, 1);
                }
            }

            this.refocusMap();
        },
        reroute(route) {
            this.currentRoute = route;
            this.currentRoute.movements = [];
            
            this.myLocations.forEach(x => {
                x.sortOrder = null;
            })
            
            this.courierLocations.forEach(x => {
                x.sortOrder = null;
            })

            this.isRouting = true;
            this.selectedInfo = null;
        },

        async saveRoute(route) {
            try {
                this.loadingMsg = 'Saving Route';
                var res = null;

                if (route.id == null) {
                    //post
                    res = await this.$BlitzIt.store.post('routes', route);
                }
                else {
                    //patch
                    res = await this.$BlitzIt.store.patch('routes', route);
                }

                route.id = res.id;
                route.rowVersion = res.rowVersion;

                this.$BlitzIt.tracker.reset(route);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async saveSupplyPointer(pointer) {
            try {
                this.loadingMsg = 'Saving Supply Pointer';
                var res = null;

                if (pointer.id == null) {
                    //post
                    res = await this.$BlitzIt.store.post('supply-pointers', pointer);
                }
                else {
                    //patch
                    res = await this.$BlitzIt.store.patch('supply-pointers', pointer);
                }

                pointer.id = res.id;
                pointer.rowVersion = res.rowVersion;

                this.$BlitzIt.tracker.reset(pointer);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async savePointers() {
            try {
                this.loadingMsg = 'Saving Changes';

                this.journeyPointers.forEach(async pointer => {
                    if (pointer.id == null) {
                        var res = await this.$BlitzIt.store.post('journey-pointers', pointer);
                        pointer.id = res.id;
                        pointer.rowVersion = res.rowVersion;
                        this.$BlitzIt.tracker.track(pointer, { 
                            trackProps: ['boundary', 'locationID', 'weekdays'], 
                            assignIgnored: { showArea: false }
                        });
                    }
                    else if (this.$BlitzIt.tracker.isChanged(pointer)) {
                        var patchRes = await this.$BlitzIt.store.patch('journey-pointers', pointer);
                        pointer.rowVersion = patchRes.rowVersion;

                        this.$BlitzIt.tracker.track(pointer, {
                            trackProps: ['boundary', 'locationID', 'weekdays'],
                            assignIgnored: { showArea: false }
                        });
                    }
                })
            }
            catch (err) {
                this.$warn(this.extractErrorDescription(err));
            }
            finally {
                //this.$inform('saved');
                this.loadingMsg = null;
            }
        },
        selectCustomer(customer) {
            if (customer != null) {
                this.customers.forEach(cust => {
                    if (cust.locations != null) {
                        cust.locations.forEach(l => {
                            l.selected = false;
                        })
                    }
                })

                if (customer.locations != null) {
                    customer.locations.forEach(l => {
                        l.selected = true;
                    })
                }
            }

            this.refocusMap();
        },
        selectLastLeg() {
            this.selectedInfo = 'To';
            this.isRouting = false;
            // if (this.isRouting) {
            //     this.selectedInfo = 'To';
            //     //finalize
            //     this.isRouting = false;
            // }
        },
        selectRouteLocation(routeLocation, route) {
            if (this.isRouting && route != null && routeLocation != null) {
                if (routeLocation.sortOrder == null) {
                    routeLocation.sortOrder = (route.movements.length + 1).toString();
                    route.movements.push(routeLocation);
                }
            }

            this.infoPosition = routeLocation.courierLocation;
            this.selectedInfo = routeLocation;
            this.windowOptions = {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            }

            this.refocusMap();
        },
        selectRoutePointer(routePointer) {
            if (this.isMatching) {
                this.addMatch(routePointer.boundary);
            }
            else {
                this.currentRoutePointer = routePointer;
                this.currentSupplyPointer = null;
                this.currentJourneyPointer = null;
                
                if (!this.containsWeekday(routePointer.weekdays, this.currentWeekday)) {
                    var s = routePointer.weekdays.split(',');
                    if (this.isLengthyArray(s)) {
                        this.currentWeekday = s[0];
                    }
                }

                this.openInfo(routePointer);
            }

            this.refocusMap();
        },
        selectJourneyPointer(pointer) {
            if (this.isMatching) {
                this.addMatch(pointer.boundary);
            }
            else {
                this.currentRoute = null;
                this.currentRoutePointer = null;
                this.currentSupplyPointer = null;
                this.currentJourneyPointer = pointer;

                if (!this.containsWeekday(pointer.weekdays, this.currentWeekday)) {
                    var s = pointer.weekdays.split(',');
                    if (this.isLengthyArray(s)) {
                        this.currentWeekday = s[0];
                    }
                }

                this.openInfo(pointer);
            }

            this.refocusMap();
        },
        selectRoute(route) {
            this.currentJourneyPointer = null;
            this.currentSupplyPointer = null;
            
            if (route != null) {
                route.showArea = true;
            }
            else {
                this.currentRoutePointer = null;
            }

            this.currentRoute = route;

            this.refocusMap();
        },
        selectSupplyPointer(supplyPointer) {
            if (this.isMatching) {
                this.addMatch(supplyPointer.boundary);
            }
            else {
                this.currentSupplyPointer = supplyPointer;
                this.currentRoute = null;
                this.currentRoutePointer = null;
                this.currentJourneyPointer = null;

                if (!this.containsWeekday(supplyPointer.weekdays, this.currentWeekday)) {
                    var s = supplyPointer.weekdays.split(',');
                    if (this.isLengthyArray(s)) {
                        this.currentWeekday = s[0];
                    }
                }

                this.infoPosition = supplyPointer.location;
                this.selectedInfo = supplyPointer;
                this.windowOptions = {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                }
            }

            this.refocusMap();
        },
        selectSimpleLocation(l) {
            this.infoPosition = l;
            this.selectedInfo = l;
            this.windowOptions = {
                pixeloffset: {
                    width: 0,
                    height: -35
                }
            }

            this.refocusMap();
        },
        async showCustomer(customer) {
            if (customer != null) {
                customer.selected = !customer.selected;

                if (customer.selected && customer.locations == null) {
                    //pull locations
                    try {
                        customer.isLoading = true;
                        var policy = await this.$BlitzIt.store.get('suppliers', customer.id, null, false, null, '/Locations/Locations');
                        console.log('policy');
                        customer.locations = policy.possibleLocations.map(x => { return Object.assign({}, { ...x, selected: false, companyAccount: customer.buyer }) });
                        console.log(customer);
                    }
                    finally {
                        customer.isLoading = false;
                    }
                    
                }
            }

            //load
            if (this.currentRoute == null && !this.isRouting) {
                this.customerLocations = this.showCustomers ? this.customers.filter(z => z.selected && z.locations != null).flatMap(m => m.locations) : [];
            }

            this.refocusMap();
        },
        toggleSelected(item) {
            item.showArea = !item.showArea;
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            zone.boundary = paths[0];
        },
    }
}
</script>